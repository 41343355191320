import
    Glide,
    {Autoplay, Breakpoints, Controls, Swipe}
    from '@glidejs/glide/dist/glide.modular.esm';
import anime from 'animejs';

// const glide = new Glide('.glide', {
//     type: 'carousel',
//     autoplay: 2000,
//     startAt: 0,
//     animationDuration: 1000,
//     peek: 50,
//     perView: 4,
//     breakpoints: {
//         480: {
//             perView: 1,
//         },
//         767: {
//             perView: 2
//         },
//         990: {
//             perView: 3
//         },
//     }
// });

function process_unsupported_browser() {
  // IntersectionObserver not supported, show all animated elements
  const els = document.querySelectorAll('.section-stagger');
  const images = document.querySelectorAll('.section-fade');

  for (let i = 0; i < els.length; i++) {
      els[i].style.opacity = 1;
  }

  for (let i = 0; i < images.length; i++) {
      images[i].style.opacity = 1;
  }
}

function registerIOs() {
    const io_options = {
      rootMargin: '0px',
      threshold: 0.5
    };
    const element_in_view = (element) => {
        if (element[0].isIntersecting && !element[0].target.dataset.loaded) {
            // Only play animations once
            element[0].target.dataset.loaded = true;
            const tl = anime.timeline();
            const stagger_els = element[0].target.querySelectorAll('.section-stagger');
            const fade_in_els = element[0].target.querySelectorAll('.section-fade');

            tl.add({
              targets: stagger_els,
              translateY: [30, 0],
              opacity: [0, 1],
              delay: anime.stagger(75),
              easing: 'easeOutExpo',
            })
            .add({
              targets: fade_in_els,
              opacity: [0, 1],
              duration: 1000,
              easing: 'linear',
            }, 0);
        }
    };
    const sections = document.querySelectorAll('section');

    sections.forEach((section) => {
        let observer = new IntersectionObserver(element_in_view, io_options);

        observer.observe(section);
    });
}

function resizeHeaderOnScroll() {
    const headerEl = document.querySelector('header');
    const distanceY = window.pageYOffset ||
        document.documentElement.scrollTop;
    const shrinkOn = 200;

    if (distanceY > shrinkOn) {
        headerEl.classList.add('scrolled');
    } else {
        headerEl.classList.remove('scrolled');
    }
}

function process_cookie_modal() {
    if (getCookie('cookie_accepted') !== 'true') {
        document.getElementById('cookies-overlay').style.display = 'block';
    }
}

document.addEventListener('DOMContentLoaded', () => {
    // Detect if browser supports IntersectionObserver
    if (!('IntersectionObserver' in window) ||
        !('IntersectionObserverEntry' in window) ||
        !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
        process_unsupported_browser();
    }
    else {
        registerIOs();
    }
    process_cookie_modal();
    // glide.mount({Autoplay, Breakpoints, Controls, Swipe});
    window.addEventListener('scroll', resizeHeaderOnScroll);
}, false);

// document.addEventListener('swup:contentReplaced', () => {
//     process_page_load();
// });

var accept_cookie_button = document.getElementById('accept_cookie_button');

accept_cookie_button.onclick = function acceptCookie () {
    setCookie('cookie_accepted', 'true');

    var hide = document.getElementById('cookies-overlay');
    console.log(hide);
    if (hide) {
        hide.classList.remove('fade-in-up');
        hide.classList.add('fade-out-down');
    }
};

function getCookie(cname) {
    let name = cname + '=';
    let etCookie = document.cookie.split(';');
    for (let i = 0; i < etCookie.length; i++) {
        let et = etCookie[i];
        while (et.charAt(0) === ' ') {
            et = et.substring(1);
        }
        if (et.indexOf(name) === 0) {
            return et.substring(name.length, et.length);
        }
    }
    return '';
}

function setCookie(cname, cvalue, exdays) {
    let date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path/';
}
